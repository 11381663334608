<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" class="pt-0">
        <v-card class="elevation-0">
          <v-card-title class="pa-4">
            <v-container fluid>
              <v-row no-gutters>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-autocomplete
                    v-model="selectedVehicles"
                    label="Select Vehicles"
                    chips
                    multiple
                    hide-no-data
                    :items="vehicles"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                  lg="4"
                  xl="4"
                  offset-md="2"
                  offset-lg="2"
                  offset-xl="2"
                >
                  <v-select
                    v-model="reportType"
                    :items="reportItems"
                    label="Select Report"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                  <div class="float-right">
                    <fab-button
                      v-if="this.selectedVehicles.length <= 5"
                      @click="getData()"
                      dark
                      top
                      xsmall
                      :icon="'mdi-play'"
                      :tooltipSpan="'Generate Report'"
                    ></fab-button
                    >&nbsp;
                    <fab-button
                      @click="getData()"
                      dark
                      top
                      xsmall
                      :icon="'mdi-refresh'"
                      :tooltipSpan="'Refresh'"
                    ></fab-button>
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="pr-3">
                  <v-menu
                    v-model="menuDateFrom"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateFrom"
                        label="Date & Time From"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateFrom"
                      :max="$dateFormat(new Date(), 'YYYY-MM-DD')"
                      @input="menuDateFrom = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-3">
                  <input
                    v-model="timeFrom"
                    type="time"
                    step="1"
                    style="width: 250px"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="pr-2">
                  <v-menu
                    v-model="menuDateTo"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateTo"
                        label="Date & Time To"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <!-- <v-date-picker
                      v-model="dateTo"
                      :max="dateTo"
                      :min="dateFrom"
                      @input="menuDateTo = false"
                    ></v-date-picker> -->
                    <v-date-picker
                      v-model="dateTo"
                      :max="dateToLimit"
                      :min="dateFrom"
                      @input="menuDateTo = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="pl-2">
                  <input
                    v-model="timeTo"
                    type="time"
                    step="1"
                    style="width: 250px"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-title>
        </v-card>
        <v-divider></v-divider>
        <v-data-table
          dense
          :headers="headers"
          :items="items"
          class="elevation-1"
          disable-pagination
          hide-default-footer
          :server-items-length="items.length"
        >
          <template
            v-for="header in headers"
            v-slot:[`header.${header.value}`]="{ headers }"
          >
            {{
              header.value === "download_all_dates"
                ? items.length > 1 && header.value === "download_all_dates"
                  ? items[0].datetimestamps.length > 1
                    ? header.text
                    : ""
                  : items.length > 1 && header.value !== "download_all_dates"
                  ? header.text
                  : ""
                : header.text
            }}
          </template>
          <template v-if="items.length === 0" v-slot:no-data>
            <v-container>
              <v-row>
                <v-col md="6" offset-md="3">
                  <span class="title font-weight-bold">NO DATA AVAILABLE.</span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else v-slot:body="{ items }">
            <!-- <thead v-if="$store.state.removeDataTableHeaders">
              <tr>
                <th
                  v-for="(item, index) in headers"
                  :key="index"
                  :class="`text-${item.align} text-no-wrap`"
                >
                  {{ item.text == "Download All Dates" ? "" : item.text }}
                </th>
              </tr>
            </thead> -->
            <tbody v-for="(item, index) in items" :key="index">
              <tr v-for="(obj, i) in item.datetimestamps" :key="i">
                <td
                  class="text-center"
                  :style="i === 0 ? '' : 'display: none;'"
                >
                  {{ index + 1 }}
                </td>
                <td
                  class="text-no-wrap text-uppercase"
                  :style="i === 0 ? '' : 'display: none;'"
                >
                  {{ item.plate_no }}
                </td>
                <td
                  :style="i === 1 ? 'border-bottom: none;' : 'display: none;'"
                  colspan="2"
                  :rowspan="item.datetimestamps.length"
                ></td>
                <td class="text-no-wrap text-center text-uppercase">
                  {{
                    i !== 0
                      ? obj.date !=
                        item.datetimestamps[i !== 0 ? i - 1 : i].date
                        ? obj.date
                        : ""
                      : obj.date
                  }}
                </td>
                <td class="text-no-wrap text-center text-uppercase">
                  {{ obj.time_from }}
                </td>
                <td class="text-no-wrap text-center text-uppercase">
                  {{ obj.time_to }}
                </td>

                <td class="text-center text-no-wrap">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="blue"
                        icon
                        @click="
                          openViewTracklistDialog(
                            item.id,
                            item.plate_no,
                            i !== 0
                              ? obj.date !=
                                item.datetimestamps[i !== 0 ? i - 1 : i].date
                                ? obj.date
                                : ''
                              : obj.date,
                            obj.time_from,
                            obj.time_to
                          )
                        "
                        v-on="on"
                      >
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                    <span>View Tracklist</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="#009444"
                        icon
                        @click="
                          downloadCSV(
                            item.id,
                            item.plate_no,
                            i !== 0
                              ? obj.date !=
                                item.datetimestamps[i !== 0 ? i - 1 : i].date
                                ? obj.date + ' ' + timeFrom
                                : ''
                              : obj.date + ' ' + timeFrom,
                            i !== 0
                              ? obj.date !=
                                item.datetimestamps[i !== 0 ? i - 1 : i].date
                                ? obj.date + ' ' + timeTo
                                : ''
                              : obj.date + ' ' + timeTo
                          )
                        "
                        v-on="on"
                      >
                        <v-icon>mdi-file-download</v-icon>
                      </v-btn>
                    </template>
                    <span
                      >Download Excel -
                      {{
                        i !== 0
                          ? obj.date !=
                            item.datetimestamps[i !== 0 ? i - 1 : i].date
                            ? obj.date
                            : ""
                          : obj.date
                      }}</span
                    >
                  </v-tooltip>
                  <snailtrail-button
                    v-if="
                      i !== 0
                        ? obj.date !=
                          item.datetimestamps[i !== 0 ? i - 1 : i].date
                          ? true
                          : false
                        : true
                    "
                    @click="
                      openViewSnailtrail(
                        item.id,
                        item.plate_no,
                        i !== 0
                          ? obj.date !=
                            item.datetimestamps[i !== 0 ? i - 1 : i].date
                            ? obj.date + ' ' + timeFrom
                            : ''
                          : obj.date + ' ' + timeFrom,
                        i !== 0
                          ? obj.date !=
                            item.datetimestamps[i !== 0 ? i - 1 : i].date
                            ? obj.date + ' ' + timeTo
                            : ''
                          : obj.date + ' ' + timeTo
                      )
                    "
                  ></snailtrail-button>
                </td>
                <td class="text-center text-no-wrap">
                  <excel-button
                    v-if="i === 0 && item.datetimestamps.length > 1"
                    :toolTip="'Download Excel - All Date'"
                    @click="
                      downloadCSV(
                        item.id,
                        item.plate_no,
                        dateFrom + ' ' + timeFrom,
                        dateTo + ' ' + timeTo
                      )
                    "
                  ></excel-button>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!---- Here is the View Current Location Dialog ------>

    <mini-dialog
      :dialog="viewCurrentLocationDialog"
      :max-width="'1000px'"
      :cardActions="false"
    >
      <v-card-title class="pl-3 gradientColor">
        <span class="headline font-weight-black white--text">{{
          `Current Location : ${this.plateData}`
        }}</span>
        <v-spacer></v-spacer>
        <v-btn dark icon @click="closeViewCurrentLocationDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0" style="height: 500px;">
        <official-map ref="map"></official-map>
      </v-card-text>
    </mini-dialog>

    <!----- Here is the View Tracklist ----------->

    <full-width-dialog
      :dialog="tracklistDialog"
      :title="'Tracklist - ' + snailtrailParams.plate_no"
      :color="'blue'"
      :dialogLoading="fullWidthDialogLoading"
      :dialogLoadingColor="'blue'"
      dark
      @closeDialog="closeViewTracklistDialog"
    >
      <v-card class="elevation-0">
        <v-container fluid class="py-0">
          <v-row>
            <v-container fluid>
              <v-data-table
                dense
                :headers="tracklistHeaders"
                :items="tracklistItems"
                class="elevation-1"
                disable-pagination
                hide-default-footer
                :server-items-length="tracklistItems.length"
              >
                <template v-if="tracklistItems.length === 0" v-slot:no-data>
                  <v-container>
                    <v-row>
                      <v-col md="6" offset-md="3">
                        <span class="title font-weight-bold"
                          >NO DATA AVAILABLE.</span
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
                <template v-else v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(obj, i) in items" :key="i">
                      <td class="text-center">
                        {{ i + 1 }}
                      </td>
                      <!-- <td class="text-no-wrap text-uppercase">
                        {{ obj.plate_no }}
                      </td> -->

                      <td class="text-no-wrap text-center text-uppercase">
                        {{ $dateFormat(obj.datetimestamp, "YYYY-MM-DD") }}
                      </td>
                      <td class="text-no-wrap text-center text-uppercase">
                        {{ $dateFormat(obj.datetimestamp, "HH:mm:ss") }}
                      </td>
                      <td class="text-no-wrap text-right text-uppercase">
                        {{ obj.speed }}
                      </td>
                      <td class="text-center text-no-wrap">
                        {{ obj.speed !== 0 ? "RUNNING" : "STOPPED" }}
                      </td>
                      <td class="text-center text-no-wrap">
                        {{ obj.engine_status !== 0 ? "ON" : "OFF" }}
                      </td>
                      <td
                        class="text-no-wrap text-uppercase viewCurrentLocation"
                        @click="viewCurrentLocation(obj.plate_no, obj)"
                      >
                        <!-- {{ obj.location ? cutHeadings(obj.location) : "--" }} -->
                        {{ obj.location }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-container>
          </v-row>
        </v-container>
      </v-card>
    </full-width-dialog>

    <!---- Here is the Downloading Excel ---->

    <mini-dialog
      :dialog="downloadExcelLoading"
      :max-width="'400'"
      :cardTitle="'Download Excel'"
      :cardText="'Downloading File, Please Wait...'"
      :confirmSave="true"
      :cardActions="false"
    >
    </mini-dialog>

    <!----- Here is the View Snailtrail ----------->

    <full-width-dialog
      :dialog="viewSnailTrail"
      :title="'Snailtrail - ' + snailtrailParams.plate_no"
      :color="'blue'"
      :dialogLoading="fullWidthDialogLoading"
      :dialogLoadingColor="'blue'"
      dark
      @closeDialog="closeViewSnailtrailDialog"
    >
      <v-card class="elevation-0">
        <v-container fluid class="py-0">
          <v-row>
            <v-container fluid>
              <v-row>
                <v-col sm="12" md="6" lg="6" xl="6">
                  <v-data-table
                    dense
                    :headers="snailtrailHeaders"
                    :items="snailtrailItems"
                    class="elevation-0 scrolling-wrapper-two"
                    disable-pagination
                    hide-default-footer
                    :server-items-length="snailtrailItems.length"
                  >
                    <template
                      v-if="snailtrailItems.length === 0"
                      v-slot:no-data
                    >
                      <v-container>
                        <v-row>
                          <v-col md="6" offset-md="3">
                            <span class="title font-weight-bold"
                              >NO DATA AVAILABLE.</span
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </template>
                    <template v-else v-slot:body="{ items }">
                      <tbody>
                        <tr
                          v-for="(obj, i) in items"
                          :key="i"
                          @click="clickSnailtrail(i)"
                          style="cursor: pointer;"
                        >
                          <td class="text-center">
                            {{ snailtrailItems.length - i }}
                          </td>
                          <td class="text-no-wrap text-center text-uppercase">
                            {{ $dateFormat(obj.datetimestamp, "YYYY-MM-DD") }}
                          </td>
                          <td class="text-no-wrap text-center text-uppercase">
                            {{ $dateFormat(obj.datetimestamp, "HH:mm:ss") }}
                          </td>
                          <td class="text-no-wrap text-right text-uppercase">
                            {{ obj.speed }}
                          </td>
                          <td
                            :class="[
                              'text-center text-no-wrap text-uppercase',
                              obj.speed !== 0 ? 'green--text' : 'red--text',
                            ]"
                          >
                            {{ obj.speed !== 0 ? "RUNNING" : "STOPPED" }}
                          </td>
                          <td class="text-center text-no-wrap">
                            {{ obj.engine_status !== 0 ? "ON" : "OFF" }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col sm="12" md="6" lg="6" xl="6" style="height: 93vh;">
                  <official-map ref="snailtrailMap"></official-map>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
        </v-container>
      </v-card>
    </full-width-dialog>
  </v-container>
</template>

<script>
import Services from "@/services/CompanyServices";
import { ExportToCsv } from "export-to-csv";

export default {
  data: () => {
    return {
      title: "Tracklist Report",
      headers: [
        {
          text: "#",
          sortable: false,
          align: "center",
          value: "#",
        },
        { text: "Plate No", sortable: false, value: "plate_no" },
        { text: "Date", sortable: false, align: "center", value: "date" },
        {
          text: "Time From",
          sortable: false,
          align: "center",
          value: "time_from",
        },
        { text: "Time To", sortable: false, align: "center", value: "time_to" },
        // { text: "Speed", sortable: false, align: "right" },
        // { text: "Status", sortable: false, align: "center" },
        // { text: "Engine", sortable: false, align: "center" },
        // { text: "Location", sortable: false },
        { text: "Action", sortable: false, align: "center", value: "action" },
        {
          text: "Download All Dates",
          sortable: false,
          align: "center",
          value: "download_all_dates",
        },
      ],
      tracklistHeaders: [
        {
          text: "#",
          sortable: false,
          align: "center",
        },
        { text: "Date", sortable: false, align: "center" },
        { text: "Time", sortable: false, align: "center" },
        { text: "Speed", sortable: false, align: "right" },
        { text: "Status", sortable: false, align: "center" },
        { text: "Engine", sortable: false, align: "center" },
        { text: "Location", sortable: false },
      ],
      items: [],
      tracklistItems: [],
      data: {},

      queryParams: {
        search: "",
      },
      vehicleQueryParams: {
        search: "",
      },
      totalItems: 0,
      itemFrom: 0,
      itemTo: 0,
      disabledNext: false,
      disabledBack: true,
      disabledLimit: true,
      page: [
        { text: 10, value: 10 },
        { text: 20, value: 20 },
        { text: 30, value: 30 },
        { text: 40, value: 40 },
        { text: 50, value: 50 },
        { text: 60, value: 60 },
        { text: 70, value: 70 },
        { text: 80, value: 80 },
        { text: 90, value: 90 },
        { text: 100, value: 100 },
      ],
      dateFrom: new Date().toISOString().substr(0, 10),
      timeFrom: "00:00:00",
      menuDateFrom: false,
      dateTo: new Date().toISOString().substr(0, 10),
      timeTo: "23:59:59",
      menuDateTo: false,
      dateToLimit: new Date().toISOString().substr(0, 10),
      selectedVehicles: [],
      vehicles: [],
      snailtrailHeaders: [
        {
          text: "#",
          sortable: false,
          align: "center",
        },
        { text: "Date", sortable: false, align: "center" },
        { text: "Time", sortable: false, align: "center" },
        { text: "Speed", sortable: false, align: "right" },
        { text: "Status", sortable: false, align: "center" },
        { text: "Engine", sortable: false, align: "center" },
      ],
      snailtrailItems: [],
      viewSnailTrail: false,
      fullWidthDialogLoading: false,
      reportItems: [
        {
          text: "Tracklist Report",
          value: "/c/tracklist-report",
        },
        {
          text: "KM Run Report",
          value: "/c/km-run-report",
        },
        {
          text: "Working Hours Report",
          value: "/c/working-hours-report",
        },
        {
          text: "Service Meter Report",
          value: "/c/smr-report",
        },
        // {
        //   text: "Temperature Report",
        //   value: "/c/temperature-report",
        // },
        {
          text: "Fuel Sensor Report",
          value: "/c/fuel-sensor-report",
        },
      ],
      viewCurrentLocationDialog: false,
      snailtrailParams: {},
      tracklistDialog: false,
      downloadExcelLoading: false,
      downloadExcelTitle: "",
      jsonData: [],
      plateData: "",
    };
  },
  created: async function() {
    if (this.$store.state.token !== null) {
      this.$store.dispatch("startLoading");

      this.$store.dispatch("dispatchReportTypeText", "Tracklist");

      this.selectedVehicles = this.$store.state.reportVehiclesArray;

      const vehiclesItems = await this.setVehicles();

      const returnedVehicles = await Promise.all(
        vehiclesItems.map((item) => {
          if (item.gps_device) {
            return {
              text: item.plate_no.toUpperCase(),
              value: item.id,
            };
          }
        })
      );

      this.vehicles = returnedVehicles.filter((item) => {
        return item;
      });

      this.$store.dispatch("endLoading");
    } else {
      this.$router.push({
        path: "/",
      });

      this.$store.dispatch("clearStore");
    }
  },
  computed: {
    reportType: {
      get() {
        // return this.$store.state.reportTypeValue
        return "/c/tracklist-report";
      },
      set(val) {
        this.items.length = 0;
        this.$store.dispatch("dispatchReportTypeValue", val);
        this.$router.push({
          path: val,
        });
      },
    },
  },
  watch: {
    selectedVehicles: function() {
      if (this.selectedVehicles.length <= 5) {
        this.$store.dispatch(
          "dispatchReportVehiclesArray",
          this.selectedVehicles
        );
      } else {
        this.$infoBar({
          type: "error",
          text: "You can select 5 vehicles only",
        });
      }
    },
    dateFrom: function() {
      this.dateTo = this.dateFrom;
      const dateTo = this.$addSevenCurrentDate(this.dateFrom);
      // this.dateTo = this.$dateValidator(dateTo);
      this.dateToLimit = this.$dateValidator(dateTo);
    },
  },
  methods: {
    search(value) {
      this.queryParams.search = value;
      this.setEmployees(this.queryParams);
    },
    async getData() {
      this.$store.dispatch("startLoading");

      const body = {
        vehicles: this.$store.state.reportVehiclesArray,
        date_time_from: this.$dateFormat(
          this.dateFrom + " " + this.timeFrom,
          "YYYY-MM-DD HH:mm:ss"
        ),
        date_time_to: this.$dateFormat(
          this.dateTo + " " + this.timeTo,
          "YYYY-MM-DD HH:mm:ss"
        ),
      };

      try {
        let response = (await Services.getTracklistDatesReport(body)).data;

        this.items = response.data;

        this.$store.dispatch("dispatchHasItems", true);
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
        // else if (error.response.status) {
        //   this.$store.dispatch("dispatchDataToLarge", true);
        // }
        else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }

      this.$store.dispatch("endLoading");
    },
    async openViewTracklistDialog(id, plate_no, date, timeFrom, timeTo) {
      this.fullWidthDialogLoading = true;

      this.tracklistDialog = true;

      this.snailtrailParams.vehicle_id = id;

      this.snailtrailParams.plate_no = plate_no;

      this.snailtrailParams.date_time_from = this.$dateFormat(
        date + " " + timeFrom,
        "YYYY-MM-DD HH:mm:ss"
      );
      this.snailtrailParams.date_time_to = this.$dateFormat(
        date + " " + timeTo,
        "YYYY-MM-DD HH:mm:ss"
      );

      const data = await this.setSnailtrail();

      this.tracklistItems = await Promise.all(
        data.map((item) => {
          return {
            plate_no,
            lat: item.lat,
            lng: item.lng,
            datetimestamp: item.datetimestamp,
            speed: item.speed,
            engine_status: item.engine_status,
            location: item.location,
          };
        })
      );

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    closeViewTracklistDialog() {
      this.tracklistItems.length = 0;
      this.tracklistDialog = false;
    },
    async setVehicles() {
      let data;

      try {
        this.vehicleQueryParams.company_id = this.$store.state.company.id;

        const response = await Services.getVehicles(this.vehicleQueryParams);
        data = response.data.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }
      return data;
    },
    async openViewSnailtrail(id, plate_no, dateTimeFrom, dateTimeTo) {
      this.snailtrailParams.vehicle_id = id;

      this.snailtrailParams.plate_no = plate_no;

      this.snailtrailParams.date_time_from = dateTimeFrom;

      this.snailtrailParams.date_time_to = dateTimeTo;

      this.fullWidthDialogLoading = true;

      this.viewSnailTrail = true;

      const data = await this.setSnailtrail();

      this.snailtrailItems = await Promise.all(
        data.map((item) => {
          return {
            plate_no,
            lat: item.lat,
            lng: item.lng,
            datetimestamp: item.datetimestamp,
            speed: item.speed,
            engine_status: item.engine_status,
            location: item.location,
          };
        })
      );

      if (data) {
        this.fullWidthDialogLoading = true;

        this.viewSnailTrailDialog = true;

        this.$store.dispatch("dispatchSnailtrail", data);

        // // this.data.totalKmRun = data.km_run;

        this.data.plate_no = plate_no;

        const boundsCoordinate = [];

        setTimeout(async () => {
          const snailtrail = await Promise.all(
            data.map((item, index) => {
              boundsCoordinate.push({
                coordinates: {
                  lat: item.lat,
                  lng: item.lng,
                },
              });

              return {
                date: this.$dateFormat(item.datetimestamp, "YYYY-MM-DD"),
                time: this.$dateFormat(item.datetimestamp, "HH:mm:ss"),
                lat: item.lat,
                lng: item.lng,
                location: item.location,
                id: index,
                remarks: item.speed !== 0 ? "RUNNING" : "STOPPED",
                // remarks: item.moving_status.toLowerCase(),
                // status: item.moving_status.toLowerCase(),
                speed: item.speed,
                engine_status: item.engine_status,
                content: `No: ${this.$store.state.snailtrail.length -
                  index}<br>Location: ${
                  item.location ? item.location : ""
                }<br>Date: ${this.$dateFormat(
                  item.datetimestamp,
                  "YYYY-MM-DD"
                )}<br>Time: ${this.$dateFormat(
                  item.datetimestamp,
                  "HH:mm:ss"
                )}<br>Speed: ${item.speed + " " + "kph"}<br>Status: ${
                  item.speed !== 0 ? "RUNNING" : "STOPPED"
                }<br>Engine: ${item.engine_status !== 0 ? "ON" : "OFF"}<br>`,
              };
            })
          );

          this.$refs.snailtrailMap.initializeMap();
          this.$refs.snailtrailMap.viewSnailTrail(snailtrail);
          // this.$refs.snailtrailMap.boundsSnailtrailMarker(snailtrail);
          this.$refs.snailtrailMap.boundsMarker(boundsCoordinate);
        }, 1000);

        setTimeout(() => {
          this.fullWidthDialogLoading = false;
        }, 600);
      }
    },
    closeViewSnailtrailDialog() {
      this.viewSnailTrail = false;
    },
    async clickSnailtrail(id) {
      await this.$refs.snailtrailMap.clickSnailtrail(id);
    },
    // async openViewSnailtrail(id, plate_no) {
    //   this.snailtrailParams.vehicle_id = id;

    //   this.snailtrailParams.plate_no = plate_no;

    //   const vehicle_id = this.snailtrailParams.vehicle_id;
    //   const vehicle_plate_no = this.snailtrailParams.plate_no;
    //   const date_from = this.$dateFormat(this.dateFrom, "YYYY-MM-DD");
    //   const date_to = this.$dateFormat(this.dateTo, "YYYY-MM-DD");
    //   const time_from = this.timeFrom;
    //   const time_to = this.timeTo;

    //   let mapForm = document.createElement("form");

    //   mapForm.target = "tracklist-map";
    //   mapForm.method = "POST"; // or "post" if appropriate
    //   mapForm.action = process.env.VUE_APP_TRACKLIST_URL;

    //   let mapInputVehicleId = document.createElement("input");
    //   mapInputVehicleId.type = "hidden";
    //   mapInputVehicleId.name = "vehicle_id";
    //   mapInputVehicleId.value = vehicle_id;

    //   mapForm.appendChild(mapInputVehicleId);

    //   let mapInputPlateNo = document.createElement("input");
    //   mapInputPlateNo.type = "hidden";
    //   mapInputPlateNo.name = "plate_no";
    //   mapInputPlateNo.value = vehicle_plate_no;

    //   mapForm.appendChild(mapInputPlateNo);

    //   let mapInputDateFrom = document.createElement("input");
    //   mapInputDateFrom.type = "hidden";
    //   mapInputDateFrom.name = "date_from";
    //   mapInputDateFrom.value = date_from;

    //   mapForm.appendChild(mapInputDateFrom);

    //   let mapInputDateTo = document.createElement("input");
    //   mapInputDateTo.type = "hidden";
    //   mapInputDateTo.name = "date_to";
    //   mapInputDateTo.value = date_to;

    //   mapForm.appendChild(mapInputDateTo);

    //   let mapInputTimeFrom = document.createElement("input");
    //   mapInputTimeFrom.type = "hidden";
    //   mapInputTimeFrom.name = "time_from";
    //   mapInputTimeFrom.value = time_from;

    //   mapForm.appendChild(mapInputTimeFrom);

    //   let mapInputTimeTo = document.createElement("input");
    //   mapInputTimeTo.type = "hidden";
    //   mapInputTimeTo.name = "time_to";
    //   mapInputTimeTo.value = time_to;

    //   mapForm.appendChild(mapInputTimeTo);

    //   let mapInputToken = document.createElement("input");
    //   mapInputToken.type = "hidden";
    //   mapInputToken.name = "token";
    //   mapInputToken.value = this.$store.state.token;

    //   mapForm.appendChild(mapInputToken);

    //   document.body.appendChild(mapForm);

    //   window.open("", "tracklist-map", "top=70,left=250,width=1600,height=800");

    //   mapForm.submit();
    // },
    async setSnailtrail() {
      let data;

      try {
        const response = await Services.getTrackingSnailtrail(
          this.snailtrailParams
        );

        // const res = response.data.data;
        // data = res.filter((item) => {
        //   return item.location != "--";
        // });
        data = response.data.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }
      return data;
    },
    async viewCurrentLocation(plate_no, data) {
      this.viewCurrentLocationDialog = true;
      this.plateData = plate_no;

      const boundsVehicles = [];
      const vehiclesCoordinate = [];

      await setTimeout(async () => {
        this.$refs.map.initializeMap();

        let contentString = ``;

        contentString = `<span class='font-weight-bold'>${plate_no.toUpperCase()}</span><hr>
        <span class='font-weight-bold'>Location:</span> ${data.location.toUpperCase()}`;

        boundsVehicles.push({
          lat: parseFloat(data.lat),
          lng: parseFloat(data.lng),
        });

        vehiclesCoordinate.push({
          coordinates: {
            lat: parseFloat(data.lat),
            lng: parseFloat(data.lng),
          },
          content: contentString,
          id: data.id,
          image_url:
            data.engine_status !== 0
              ? require("../../../assets/marker_icons/map/mv_eon_running.svg")
              : require("../../../assets/marker_icons/map/mv_eon_stopped.svg"),
          label_content: plate_no,
          in_dashboard: true,
        });

        await this.$refs.map.insertMultipleMarker(vehiclesCoordinate);
        if (boundsVehicles.length !== 0) {
          await this.$refs.map.boundsOfAllMarkers(boundsVehicles);
        }
      }, 1);
    },
    closeViewCurrentLocationDialog() {
      this.viewCurrentLocationDialog = false;
    },
    async downloadCSV(id, plate_no, date_time_from, date_time_to) {
      this.downloadExcelLoading = true;

      const response = (
        await Services.getTracklistDownloadData({
          vehicle_id: id,
          date_time_from,
          date_time_to,
        })
      ).data;

      if (response.data.length != 0) {
        const options = {
          fieldSeparator: ",",
          quoteStrings: '"',
          decimalSeparator: ".",
          filename:
            "Tracklist-Report-" +
            plate_no +
            "-" +
            this.$dateFormat(this.dateFrom, "YYYYMMDD") +
            this.timeFrom +
            "-" +
            this.$dateFormat(this.dateTo, "YYYYMMDD") +
            this.timeTo,
          showLabels: true,
          useTextFile: false,
          useBom: true,
          headers: [
            "Plate No.",
            "Date",
            "Time",
            "Speed",
            "Status",
            "Engine",
            "Location",
          ],
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(response.data);

        this.downloadExcelLoading = false;
      } else {
        this.downloadExcelLoading = false;

        this.$infoBar({
          type: "error",
          text: "No Data Available!",
        });
      }
    },
    cutHeadings(data) {
      let text = null;
      text = data.length < 5 ? data : data.slice(0, 100) + "...";
      return text;
    },
    biggestNumberInArray(arr) {
      // The largest number at first should be the first element or null for empty array
      let largest = arr[0] || null;

      // Current number, handled by the loop
      let number = null;
      for (let i = 0; i < arr.length; i++) {
        // Update current number
        number = arr[i];

        // Compares stored largest number with current number, stores the largest one
        largest = Math.max(largest, number);
      }

      return largest;
    },
    titleCase(str) {
      let splitStr = str.toLowerCase().split(" ");
      for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
  },
  async beforeDestroy() {
    this.$store.dispatch("dispatchReportTypeValue", "");
  },
};
</script>

<style scoped>
.gradientColor {
  background-color: #990000;
  background-image: linear-gradient(147deg, #990000 0%, #ff0000 74%);
}

.viewCurrentLocation {
  cursor: pointer;
  color: red;
}

.viewCurrentLocation:hover {
  text-decoration: underline;
}

input[type="time"] {
  /* border: 5px solid #b71c1c; */
  color: #000000;
  font-size: 14px;
  font-family: helvetica;
  width: 100%;
}

input[type="time"]:focus {
  outline: none;
}

/* Wrapper around the hour, minute, second, and am/pm fields as well as 
the up and down buttons and the 'X' button */
input[type="time"]::-webkit-datetime-edit-fields-wrapper {
  display: flex;
}

/* The space between the fields - between hour and minute, the minute and 
second, second and am/pm */
input[type="time"]::-webkit-datetime-edit-text {
  padding: 19px 4px;
}

/* The naming convention for the hour, minute, second, and am/pm field is
`-webkit-datetime-edit-{field}-field` */

/* Hour */
input[type="time"]::-webkit-datetime-edit-hour-field {
  background-color: #f2f4f5;
  border-radius: 15%;
  padding: 19px 13px;
}

/* Minute */
input[type="time"]::-webkit-datetime-edit-minute-field {
  background-color: #f2f4f5;
  border-radius: 15%;
  padding: 19px 13px;
}

/* Second */
input[type="time"]::-webkit-datetime-edit-second-field {
  background-color: #f2f4f5;
  border-radius: 15%;
  padding: 19px 13px;
}

/* AM/PM */
input[type="time"]::-webkit-datetime-edit-ampm-field {
  background-color: #b71c1c;
  border-radius: 15%;
  color: #fff;
  padding: 19px 13px;
}

/* 'X' button for resetting/clearing time */
input[type="time"]::-webkit-clear-button {
  display: none;
}

/* Up/Down arrows for incrementing/decrementing the value */
input[type="time"]::-webkit-inner-spin-button {
  display: none;
}

tbody tr:hover {
  background-color: transparent !important;
}

.my-border td:nth-child(1) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(2) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(3) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(4) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(5) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(6) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(7) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(8) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(9) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(10) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(11) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(12) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(13) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(14) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(15) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(16) {
  border-top: 1px solid #dddddd;
}

.scrolling-wrapper-two {
  overflow-x: hidden;
  height: 92vh;
  overflow-y: scroll;
  white-space: nowrap;
}

@media (max-width: 360px) and (max-height: 640px) {
  .heightMap {
    height: 100%;
  }
}

@media (max-width: 411px) and (max-height: 731px) {
  .heightMap {
    height: 100%;
  }
}

@media (max-width: 411px) and (max-height: 823px) {
  .heightMap {
    height: 100%;
  }
}

@media (max-width: 320px) and (max-height: 568px) {
  .heightMap {
    height: 100%;
  }
}

@media (max-width: 375px) and (max-height: 667px) {
  .heightMap {
    height: 100%;
  }
}

@media (max-width: 414px) and (max-height: 736px) {
  .heightMap {
    height: 100%;
  }
}

@media (max-width: 375px) and (max-height: 812px) {
  .heightMap {
    height: 100%;
  }
}

@media (max-width: 768px) and (max-height: 1024px) {
  .heightMap {
    height: 100%;
  }
}
</style>
